import {
  reactive, onMounted, toRefs,
  getCurrentInstance,
} from 'vue'
import { cloneDeep, get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCrudShow({
  item = {},
  getItem = async () => {},

  getMoreData = async () => {},
  deleteEntity = async () => {},
  getQuestionTextBeforeDeletion = i => `Сущьность с ID = ${i.id} будет удалена`,
  getTextAfterDeletion = i => `Сущьность с ID = ${i.id} была удалена`,
  runAfterDeletion = () => {},
}) {
  const instance = getCurrentInstance().proxy

  const data = reactive({
    item: cloneDeep(item),
    loading: false,
  })

  const getData = async () => {
    await Promise.all([
      getItem(),
      getMoreData(),
    ])
  }

  const deleteItem = async i => {
    const resButton = await instance.$swal({
      title: 'Вы уверены?',
      text: getQuestionTextBeforeDeletion(i),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Да, удалить!',
      cancelButtonText: 'Отмена',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (resButton.value) {
      data.loading = true
      try {
        await deleteEntity(i)
        instance.$toast({
          component: ToastificationContent,
          props: {
            title: 'Удалено!',
            icon: 'BellIcon',
            text: getTextAfterDeletion(i),
            variant: 'success',
          },
        })

        runAfterDeletion()
      } catch (e) {
        instance.$swal({
          icon: 'error',
          title: 'Ошибка!',
          text: get(e, 'response.data.error', 'Что то пошло не так, попробуйте позже или обратитесь в техническую поддержку.'),
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } finally {
        data.loading = false
      }
    }
  }

  onMounted(async () => {
    data.loading = true
    await getData()
    data.loading = false
  })

  return {
    ...toRefs(data),
    deleteItem,
  }
}
